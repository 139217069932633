import React from "react";
import PropTypes from "prop-types";
import DateInput from "./FormComponents/DateInput";
import PostcodeAutocomplete from "./FormComponents/PostcodeAutocomplete";
import SchoolAutocomplete from "./FormComponents/SchoolAutocomplete";
import { SelectAndOther } from "./FormComponents/SelectAndOther";
import MultipleSelect from "./MultipleSelect";

function FieldComponent(args) {
  const { type, schemaObj, ...props } = args;
  const { className, name, id, onChange, value, otherText, ...rest } = args;

  const obj = {
    text: <input {...props} className={`${className}`} type="text" />,
    select: (
      <select {...props} className={`${className} max-w-xs`}>
        <option value="" disabled selected hidden></option>
        {schemaObj?.options?.map((opt) => (
          <option value={opt}>{opt}</option>
        ))}
      </select>
    ),
    tags: (
      <MultipleSelect
        schemaObj={schemaObj}
        id={id}
        value={value}
        onChange={onChange}
        {...props}
        multiple
        className={`${className} max-w-lg`}
      />
    ),
    multipleSelect: (
      <MultipleSelect
        schemaObj={schemaObj}
        id={id}
        value={value}
        onChange={onChange}
        {...props}
        multiple
        className={`${className} max-w-lg`}
      />
    ),
    selectAndOther: (
      <SelectAndOther
        schemaObj={schemaObj}
        {...props}
        id={id}
        otherText={schemaObj?.otherText}
      />
    ),
    number: <input {...props} type="number" />,
    phoneNumber: <input {...props} type="tel" autoComplete="tel" />,
    email: <input {...props} type="email" autoComplete="email" />,
    postcode: (
      <div className="max-w-xs">
        <PostcodeAutocomplete
          {...props}
          defaultValue={value}
          onSelect={(postcode) => {
            onChange({
              target: {
                value: postcode,
                id,
              },
            });
          }}
        />
      </div>
    ),
    boolean: (
      <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
        <div
          className="flex items-center space-x-4 cursor-pointer"
          onChange={props.onChange}
        >
          <input
            id={props.id}
            name={props.id}
            type="radio"
            required={props.required}
            checked={props.value === "Yes"}
            value="Yes"
            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-greyClear"
          />
          <label
            htmlFor="Yes"
            className="ml-3 block text-sm font-medium text-gray-700"
          >
            Yes
          </label>
          <input
            id={props.id}
            value="No"
            name={props.id}
            checked={props.value === "No"}
            type="radio"
            required={props.required}
            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-greyClear"
          />
          <label
            htmlFor="No"
            className="ml-3 block text-sm font-medium text-gray-700"
          >
            No
          </label>
        </div>
      </div>
    ),
    date: (
      <DateInput
        {...props}
        onChange={(date) => {
          onChange({
            target: {
              value: date,
              id,
            },
          });
        }}
      />
    ),
    school: (
      <SchoolAutocomplete
        {...props}
        defaultValue={value}
        onSelect={(schoolId) => {
          onChange({
            target: {
              value: schoolId,
              id,
            },
          });
        }}
      />
    ),
    // country: <input {...props} type="checkbox" />,
    // benefits: <input {...props} type="checkbox" />,
  };
  return obj[type] || null;
}

export default FieldComponent;

import React, { useState, useRef, useEffect, useId } from "react";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/20/solid";

const MultipleSelect = ({
  schemaObj,
  value,
  id,
  onChange,
  className,
  multiple,
  required,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [focusedOptionIndex, setFocusedOptionIndex] = useState(-1);
  const multipleSelectVal = Array.isArray(value)
    ? value
    : typeof value === "string"
    ? [value]
    : [];
  const uniqueId = useId();
  const selectId = id || uniqueId;
  const containerRef = useRef(null);
  const optionsRef = useRef([]);

  const filteredOptions =
    schemaObj?.options?.filter(
      (opt) => !multipleSelectVal.includes(opt) || multiple
    ) || [];

  const handleOptionClick = (option) => {
    const newValue = multipleSelectVal.includes(option)
      ? multipleSelectVal.filter((item) => item !== option)
      : [...multipleSelectVal, option];

    onChange({ target: { id: selectId, value: newValue } });
    if (!multiple) {
      setIsOpen(false);
      containerRef.current.focus();
    }
  };

  const removeOption = (option, event) => {
    event.stopPropagation();
    const newValue = multipleSelectVal.filter((item) => item !== option);
    onChange({ target: { id: selectId, value: newValue } });
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setFocusedOptionIndex(0);
    }
  };

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleKeyDown = (event) => {
    switch (event.key) {
      case "Enter":
      case " ":
        if (!isOpen) {
          setIsOpen(true);
          setFocusedOptionIndex(0);
        } else if (focusedOptionIndex !== -1) {
          handleOptionClick(filteredOptions[focusedOptionIndex]);
        }
        event.preventDefault();
        break;
      case "Escape":
        setIsOpen(false);
        containerRef.current.focus();
        break;
      case "ArrowDown":
        if (!isOpen) {
          setIsOpen(true);
        }
        setFocusedOptionIndex((prevIndex) =>
          Math.min(prevIndex + 1, filteredOptions.length - 1)
        );
        event.preventDefault();
        break;
      case "ArrowUp":
        setFocusedOptionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (isOpen && focusedOptionIndex !== -1) {
      optionsRef.current[focusedOptionIndex]?.focus();
    }
  }, [isOpen, focusedOptionIndex]);

  return (
    <div className="relative" ref={containerRef}>
      <div
        className={`flex flex-wrap gap-2 p-2 border rounded-md bg-white cursor-pointer ${className} ${
          required && multipleSelectVal.length === 0
            ? "border-red-500"
            : "border-greyClear"
        }`}
        onClick={toggleDropdown}
        onKeyDown={handleKeyDown}
        tabIndex="0"
        role="combobox"
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        aria-controls={`${selectId}-listbox`}
      >
        {multipleSelectVal.map((val) => (
          <span
            key={val}
            className="inline-flex items-center px-2 py-1 rounded-full text-sm font-medium bg-indigo-100 text-indigo-700"
          >
            {val}
            <button
              type="button"
              onClick={(e) => removeOption(val, e)}
              className="ml-1 rounded-full p-1 inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              <span className="sr-only">Remove {val}</span>
              <XMarkIcon className="h-4 w-4" />
            </button>
          </span>
        ))}
        {multipleSelectVal.length === 0 && (
          <span className="text-gray-400">Select options</span>
        )}
        <ChevronDownIcon className="h-5 w-5 ml-auto" />
      </div>
      {isOpen && (
        <ul
          className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto"
          role="listbox"
          id={`${selectId}-listbox`}
          aria-multiselectable={multiple}
        >
          {filteredOptions.map((opt, index) => (
            <li
              key={opt}
              ref={(el) => (optionsRef.current[index] = el)}
              className={`px-4 py-2 cursor-pointer hover:bg-indigo-100 ${
                multipleSelectVal.includes(opt) ? "bg-indigo-50" : ""
              } ${focusedOptionIndex === index ? "bg-indigo-200" : ""}`}
              onClick={() => handleOptionClick(opt)}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  handleOptionClick(opt);
                  e.preventDefault();
                }
              }}
              role="option"
              aria-selected={multipleSelectVal.includes(opt)}
              tabIndex="-1"
            >
              {opt}
            </li>
          ))}
        </ul>
      )}
      <select
        id={selectId}
        name={selectId}
        multiple={multiple}
        required={required}
        value={multipleSelectVal}
        onChange={() => {}} // Dummy onChange to prevent React warning
        className="sr-only"
        aria-hidden="true"
        tabIndex="-1"
        {...props}
      >
        {multipleSelectVal.map((val) => (
          <option key={val} value={val}>
            {val}
          </option>
        ))}
      </select>
    </div>
  );
};

export default MultipleSelect;
